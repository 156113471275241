import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [];

export const dictionary = {
		"/": [15],
		"/(app)/account": [16,[2,3]],
		"/(app)/account/activity-feed": [17,[2,3]],
		"/(app)/account/credits": [18,[2,3]],
		"/(app)/account/purchase-history": [19,[2,3]],
		"/(app)/account/subscription": [20,[2,3]],
		"/dev": [47],
		"/forgot-password": [48],
		"/(app)/get-started": [21,[2,4]],
		"/(app)/my-videos": [22,[2]],
		"/(app)/my-videos/details": [31,[2]],
		"/(app)/my-videos/[video_id]": [23,[2,5]],
		"/(app)/my-videos/[video_id]/video-editor/audio/music": [24,[2,5,6,7]],
		"/(app)/my-videos/[video_id]/video-editor/captions/custom-style": [25,[2,5,6,8]],
		"/(app)/my-videos/[video_id]/video-editor/captions/default-style": [26,[2,5,6,8]],
		"/(app)/my-videos/[video_id]/video-editor/captions/script": [27,[2,5,6,8]],
		"/(app)/my-videos/[video_id]/video-editor/effects": [28,[2,5,6]],
		"/(app)/my-videos/[video_id]/video-editor/image/image-styling": [29,[2,5,6,9]],
		"/(app)/my-videos/[video_id]/video-editor/image/images": [30,[2,5,6,9]],
		"/(app)/new-create-custom/audio/music": [34,[2,11,12]],
		"/(app)/new-create-custom/audio/voice": [35,[2,11,12]],
		"/(app)/new-create-custom/captions/custom-style": [36,[2,11,13]],
		"/(app)/new-create-custom/captions/default-style": [37,[2,11,13]],
		"/(app)/new-create-custom/effects": [38,[2,11]],
		"/(app)/new-create-custom/image": [39,[2,11]],
		"/(app)/new-create-custom/script": [40,[2,11]],
		"/(app)/new-create/script": [32,[2,10]],
		"/(app)/new-create/templates": [33,[2,10]],
		"/(app)/payments/thankyou": [41,[2]],
		"/sentry-example": [49],
		"/sign-in": [50],
		"/sign-up": [51],
		"/(app)/templates": [42,[2]],
		"/(app)/workspace": [43,[2,14]],
		"/(app)/workspace/ai-image-short": [44,[2,14]],
		"/(app)/workspace/would-you-rather": [45,[2,14]],
		"/(app)/workspace/would-you-rather/project/[project_id]": [46,[2,14]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';